<template>
  <div class="history-return">
    <nav style="margin-top:10px">
      <span>筛选日期：</span>
      <el-date-picker
        size="mini"
        type="daterange"
        v-model="dateRange"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
		value-format="YYYY-MM-DD HH:mm:ss"
      >
      </el-date-picker>
      <el-button type="primary" size="mini" style="margin-left: 8px" @click="query" icon="el-icon-search"
        >查询</el-button
      >
    </nav>
    <tab-table
      ref="child"
      :routeNames = 'routeNames'
      :columns="columns"
      :dateRange="dateRange"
      :findApi="findNetReturnGrowthRate"
      :exportApi="exportNetReturnGrowthRate"
    />
  </div>
</template>

<script>
import tabTable from "../tab-table";
import { defineComponent, reactive, ref, toRefs, onMounted } from "vue";
import { findNetReturnGrowthRate, exportNetReturnGrowthRate } from "../apis";
import { historyReturnData } from "../datas";
import {} from "../methods";
import { ElMessage } from 'element-plus';
import dayjs from "dayjs";

export default defineComponent({
  name: "history-return",
  components: {
    tabTable,
  },
  setup() {
    let data = reactive(historyReturnData);
    data.dateRange = ['','']
　　const child = ref(null);
    onMounted(() => {
      let date = Date.parse(new Date()) - 24 * 60 * 60000
      let date2 = Date.parse(new Date()) - 24 * 60 * 60000
      data.dateRange[0] = dayjs(date).format("YYYY-MM-DD") + " 00:00:00"
      // data.dateRange[1]=dayjs(date2).format("YYYY-MM-DD") + " 23:59:59"
      data.dateRange[1] = dayjs(date2).format("YYYY-MM-DD")
      query()
    });

    const query = () => {
      // if (data.dateRange && data.dateRange!=null) {
      //   data.dateRange[0] = dayjs(date).format("YYYY-MM-DD") + " 00:00:00"
      //   data.dateRange[1]=dayjs(date2).format("YYYY-MM-DD") + " 23:59:59"
      // }
      data.dateRange[1] =  data.dateRange[1] ? data.dateRange[1].slice (0,10) + " 23:59:59" : '' 
      !data.dateRange[1]|| data.dateRange == null ? ElMessage.warning('请选择筛选时间') : child.value.queryHistory()
    }

    return {
      ...toRefs(data),
      findNetReturnGrowthRate,
      exportNetReturnGrowthRate,
      child,
      query,
    };
  },
});
</script>

<style scoped lang="less">
.history-return {
  position: relative;
  nav {
    margin-bottom: 8px;
    span {
      font-size: 14px;
    }
  }
}
</style>